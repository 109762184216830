@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

body {
  margin: 0;
  font-family: Manrope, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: Manrope, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
